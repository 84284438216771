import React from "react";
import {DefaultLayout} from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/companyoverview.module.scss"
export default () =>
  <DefaultLayout className="">
    template

    <div className={styles.test1}>
      <a className={styles.btnFloat}>View All</a>
    </div>

    <div className={styles.test2}>
      <a className={styles.btnChange}>
        <span>Contact</span><span>View All</span>
      </a>
    </div>

    <div className={styles.test3}>
      <a className={styles.btnTransform}>
        <span>上記内容を確認のうえエントリーする</span><span>View All</span></a>
      <h2>※４つともあとで矢印をつける<br/>ドロップシャドウをニューモフィズムデザインに合わす<br/>この3ブロックはこのページにはいらないのでレイアウト.tsxにも保存します。</h2>
    </div>

  </DefaultLayout>;